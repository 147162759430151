import React, { useEffect, useState } from "react";
import Header from "../components/header/header";
import { brandData } from "../data/global/branddata";
import { menuData, btnData } from "../data/global/menudata";
import Navmenu from "../components/navmenu/navmenu";
import Dashboard from "../components/navitems/invoice/invoice";
import Enquiries from "../components/navitems/bilty/bilty";
import { Navigate, useNavigate } from "react-router-dom";
import { toast,Toaster } from "react-hot-toast";
import Loadingslip from "../components/navitems/loadingslip/loadingslip";

const Dashpage = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("invoice");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  
  const handleBeforeUnload = () => {
    localStorage.removeItem("isLoggedIn");
  };
  
  const handlePageReload = () => {
    window.addEventListener("beforeunload", handleBeforeUnload);
  };
  
  useEffect(() => {
    if (!localStorage.getItem("isLoggedIn")) {
      navigate("/login");
    }
  
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handlePageReload);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handlePageReload);
    };
  }, []);

  


  

  return (
    <>
    <Toaster/>
      <Header handleOptionChange={handleOptionChange} brandData={brandData} menuData={menuData} btnData={btnData} />
      <Navmenu handleOptionChange={handleOptionChange} />
      {selectedOption === "invoice" && <Dashboard />}
      {selectedOption === "bilty" && <Enquiries />}
      {selectedOption === "loadingslip" && <Loadingslip />}
    </>
  );
};

export default Dashpage;
