import React, { useEffect, useState } from 'react';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import invoicetemp from './invoicetemp.docx';
import Button from '../../Button/Button';
import './invoice.css';
import { toast,Toaster } from "react-hot-toast";
import axios from 'axios';
import converter from 'number-to-words';
import { createinvoiceroute } from '../../../api/apiroutes';

const Dashboard = () => {


  
const [invoiceNo, setInvoiceNo] = useState('');
const [srNo, setSrNo] = useState('');
const [fixedInvoicepart, setfixedInvoicepart] = useState('');
const [invoiceDate, setInvoiceDate] = useState('');
const [vehicleNo, setVehicleNo] = useState('');
const [dateOfSupply, setDateOfSupply] = useState('');
const [consignorName, setConsignorName] = useState('');
const [consignorAddress, setConsignorAddress] = useState('');
const [consignorGstin, setConsignorGstin] = useState('');
const [consignorState, setConsignorState] = useState('');
const [consignorStateCode, setConsignorStateCode] = useState('');
const [consigneeName, setConsigneeName] = useState('');
const [consigneeAddress, setConsigneeAddress] = useState('');
const [consigneeGstin, setConsigneeGstin] = useState('');
const [consigneeState, setConsigneeState] = useState('');
const [consigneeStateCode, setConsigneeStateCode] = useState('');
const [grNo, setGrNo] = useState('');
const [noOfPackages, setNoOfPackages] = useState('');
const [weight, setWeight] = useState('');
const [freightCharge, setFreightCharge] = useState('');
const [cgst, setCgst] = useState('');
const [upgst, setUpgst] = useState('');
const [igst, setIgst] = useState('');
const [taxType, setTaxType] = useState('');
const [grandTotal, setGrandTotal] = useState('');
const [amountInWords, setAmountInWords] = useState('');


useEffect(() => {
  if(freightCharge!=='' && grandTotal!=='' ){
    const word=converter.toWords(grandTotal);
    setAmountInWords(word);
    }
},[amountInWords,grandTotal,freightCharge,taxType])

useEffect(() => {
  if (taxType === 'cgst') {
    setGrandTotal((freightCharge * 1.06).toFixed(0));
    setCgst((freightCharge * 0.06).toFixed(0));
  } else if (taxType === 'upgstcgst' || taxType === 'cgstupgst') {
    setGrandTotal((freightCharge * 1.12).toFixed(0));
  }
  else if (taxType === 'upgst') {
    setGrandTotal((freightCharge * 1.06).toFixed(0));
    setUpgst((freightCharge * 0.06).toFixed(0));
  } else if (taxType === 'igst') {
    setGrandTotal((freightCharge * 1.120).toFixed(0));
    setIgst((freightCharge * 0.12).toFixed(0));
  } else if(taxType===''){
    setGrandTotal(freightCharge);
  }
},[taxType,freightCharge,amountInWords,grandTotal])





console.log(amountInWords);






  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }

  const generateDocument = () => {

    if (!invoiceNo || !invoiceDate || !consignorName || !consignorAddress || !consignorGstin || !consigneeName || !consigneeAddress || !consigneeGstin || !grNo || !freightCharge || !grandTotal || !amountInWords) {
      toast.error("Please fill all the required fields having red labels");
      return;
    }

    loadFile(invoicetemp, function (error, content) {
      if (error) {
        throw error;
      }
      var zip = new PizZip(content);
      var doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.setData({
        invoiceNo: invoiceNo,
        invoiceDate: invoiceDate,
        vehicleNo: vehicleNo,
        dateOfSupply: dateOfSupply,
        consignorName: consignorName,
        consignorAddress: consignorAddress,
        consignorGstin: consignorGstin,
        consignorState: consignorState,
        consignorStateCode: consignorStateCode,
        consigneeName: consigneeName,
        consigneeAddress: consigneeAddress,
        consigneeGstin: consigneeGstin,
        consigneeState: consigneeState,
        consigneeStateCode: consigneeStateCode,
        grNo: grNo,
        noOfPackages: noOfPackages,
        weight: weight,
        freightCharge: freightCharge,
        cgst: cgst,
        upgst: upgst,
        igst: igst,
        grandTotal: grandTotal,
        amountInWords: amountInWords,
      });
      
      try {
        doc.render();
      } catch (error) {
        throw error;
      }
      const docxFilePath = `invoice_${invoiceNo}.docx`;
      var out = doc.getZip().generate({
        type: 'blob',
        mimeType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      saveAs(out, docxFilePath);
    });

    try {
      const {data} =  axios.post(createinvoiceroute, {
        invoiceNo,
        invoiceDate,
        vehicleNo,
        dateOfSupply,
        consignorName,
        consignorAddress,
        consignorGstin,
        consignorState,
        consignorStateCode,
        consigneeName,
        consigneeAddress,
        consigneeGstin,
        consigneeState,
        consigneeStateCode,
        grNo,
        noOfPackages,
        weight,
        freightCharge,
        cgst,
        upgst,
        igst,
        grandTotal,
        amountInWords
      });
      toast.success("Invoice created successfully and posted to database. You can view invoices generated here in admin website", {
        duration: 8000,
      });   
      setInvoiceNo('');
setInvoiceDate('');
setVehicleNo('');
setDateOfSupply('');
setConsignorName('');
setConsignorAddress('');
setConsignorGstin('');
setConsignorState('');
setConsignorStateCode('');
setConsigneeName('');
setConsigneeAddress('');
setConsigneeGstin('');
setConsigneeState('');
setConsigneeStateCode('');
setGrNo('');
setNoOfPackages('');
setWeight('');
setFreightCharge('');
setCgst('');
setUpgst('');
setIgst('');
setGrandTotal('');
setAmountInWords('');

    } catch (error) {
      console.error(error); 
    }
    
  };




  useEffect(() => {
    const generatedInvoiceNo = 'GT/23-24/';
      setfixedInvoicepart(generatedInvoiceNo);
  }, []);

  useEffect(() => {
  setInvoiceNo(`${fixedInvoicepart}${srNo}`);
  }, [srNo]);





const handleTaxTypeChange = (e) => {
  const newValue = e.target.value;
  if (e.target.checked) {
    // Append the selected tax type without spacing
    setTaxType((prevTaxType) => prevTaxType + newValue);
  } else {
    // Remove the deselected tax type without spacing
    setTaxType((prevTaxType) =>
      prevTaxType.replace(newValue, '').replace(/\s+/g, '')
    );
  }
};








  return (
    <div className="invoice">
      <Toaster />
      <h4>generate tax invoice</h4>
      <p>note: all fields with red labels are mandatory. 
      Enter Details Accurately to Prevent Heavy Government Fines
      </p>
      <form id='invoiceform' className='invoiceform'>
        <h5>invoice details</h5>
        <div className="input-bx" style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
          <p style={{marginTop:"8px",width:"70px"}}>{fixedInvoicepart}</p>
        <input
          type="text"
          value={srNo}
          style={{width:"180px",marginLeft:"2px"}}
          onChange={(e) => setSrNo(e.target.value)}
          placeholder="Enter serial no."
        />
        <span className='imp'>invoice no.</span>
      </div>

<div class="input-bx">
  <input
    type="text"
    value={invoiceDate}
    onChange={(e) => setInvoiceDate(e.target.value)}
    placeholder="Invoice Date"
  />
  <span className='imp'>invoice date</span>
</div>
<div class="input-bx">
  <input
    type="text"
    value={vehicleNo}
    onChange={(e) => setVehicleNo(e.target.value)}
    placeholder="Vehicle No."
  />
  <span>vehicle no.</span>
</div>
<div class="input-bx">
  <input
    type="text"
    value={dateOfSupply}
    onChange={(e) => setDateOfSupply(e.target.value)}
    placeholder="Date of Supply"
  />
  <span>date of supply</span>
</div>
<h5>consignor details</h5>
<div class="input-bx">
  <input
    type="text"
    value={consignorName}
    onChange={(e) => setConsignorName(e.target.value)}
    placeholder="Consignor Name"
  />
  <span className='imp'>consignor name</span>
</div>
<div class="input-bx">
  <input
    type="text"
    value={consignorAddress}
    onChange={(e) => setConsignorAddress(e.target.value)}
    placeholder="Consignor Address"
  />
  <span className='imp'>consignor address</span>
</div>
<div class="input-bx">
  <input
    type="text"
    value={consignorGstin}
    onChange={(e) => setConsignorGstin(e.target.value)}
    placeholder="Consignor GSTIN"
  />
  <span className='imp'>consignor GSTIN</span>
</div>
<div class="input-bx">
  <input
    type="text"
    value={consignorState}
    onChange={(e) => setConsignorState(e.target.value)}
    placeholder="Consignor State"
  />
  <span>consignor state</span>
</div>
<div class="input-bx">
  <input
    type="text"
    value={consignorStateCode}
    onChange={(e) => setConsignorStateCode(e.target.value)}
    placeholder="Consignor State Code"
  />
  <span>consignor state code</span>
</div>
<h5>consignee details</h5>
<div class="input-bx">
  <input
    type="text"
    value={consigneeName}
    onChange={(e) => setConsigneeName(e.target.value)}
    placeholder="Consignee Name"
  />
  <span className='imp'>consignee name</span>
</div>
<div class="input-bx">
  <input
    type="text"
    value={consigneeAddress}
    onChange={(e) => setConsigneeAddress(e.target.value)}
    placeholder="Consignee Address"
  />
  <span className='imp'>consignee address</span>
</div>
<div class="input-bx">
  <input
    type="text"
    value={consigneeGstin}
    onChange={(e) => setConsigneeGstin(e.target.value)}
    placeholder="Consignee GSTIN"
  />
  <span className='imp'>consignee GSTIN</span>
</div>
<div class="input-bx">
  <input
    type="text"
    value={consigneeState}
    onChange={(e) => setConsigneeState(e.target.value)}
    placeholder="Consignee State"
  />
  <span>consignee state</span>
</div>
<div class="input-bx">
  <input
    type="text"
    value={consigneeStateCode}
    onChange={(e) => setConsigneeStateCode(e.target.value)}
    placeholder="Consignee State Code"
  />
  <span>consignee state code</span>
</div>
<h5>consignment details</h5>
<div class="input-bx">
  <input
    type="text"
    value={grNo}
    onChange={(e) => setGrNo(e.target.value)}
    placeholder="GR No."
  />
  <span className='imp'>GR no.</span>
</div>
<div class="input-bx">
  <input
    type="text"
    value={noOfPackages}
    onChange={(e) => setNoOfPackages(e.target.value)}
    placeholder="No. of Packages"
  />
  <span>no. of packages</span>
</div>
<div class="input-bx">
  <input
    type="text"
    value={weight}
    onChange={(e) => setWeight(e.target.value)}
    placeholder="Weight"
  />
  <span>weight</span>
</div>
<h5>charges</h5>
<div class="input-bx">
  <input
    type="number" 
    id="numericInput" 
    oninput="validateNumberInput(this)"
    value={freightCharge}
    onChange={(e) => setFreightCharge(e.target.value)}
    placeholder="Freight Charge"
  />
  <span className='imp'>freight charge</span>
</div>
<div style={{display:"flex",flexDirection:"row", marginTop:"10px",marginBottom:"10px"}} >
<div className="input-bx" style={{ width: '60px' }}>
        <input
          type="checkbox"
          value="cgst"
          onChange={handleTaxTypeChange}
        />
        <span>CGST</span>
      </div>
      <div className="input-bx" style={{ width: '60px' }}>
        <input
          type="checkbox"
          value="upgst"
          onChange={handleTaxTypeChange}
        />
        <span>UPGST</span>
      </div>
      <div className="input-bx" style={{ width: '60px' }}>
        <input
          type="checkbox"
          value="igst"
          onChange={handleTaxTypeChange}
        />
        <span>IGST</span>
      </div>
      </div>
<div class="input-bx">
  <input
    type="text"
    value={grandTotal}
    onChange={(e) => setGrandTotal(e.target.value)}
    placeholder="Grand Total"
  />
  <span className='imp'>grand total</span>
</div>
<div class="input-bx">
  <input
  value={amountInWords}
    onChange={(e) => setAmountInWords(e.target.value)}
    placeholder="Amount in Words"
  />
  <span className='imp'>amount in words</span>
</div>

      </form>
      <div onClick={generateDocument}>
        <div className="invoice-button">
      <Button >Generate Invoice</Button>
      </div>
      </div>
    </div>
  );
};

export default Dashboard;
