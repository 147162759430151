
import React, { useState } from 'react';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import invoicetemp from './grtemp.docx';
import Button from '../../Button/Button';
import './bilty.css';
import { toast,Toaster } from "react-hot-toast";
import axios from 'axios';
import { addconsignmentroute } from '../../../api/apiroutes';

const Bilty = () => {


  
  const [consignorName, setConsignorName] = useState('');
  const [consignorAddress, setConsignorAddress] = useState('');
  const [consignorCity, setConsignorCity] = useState('');
  const [consignorPincode, setConsignorPincode] = useState('');
  const [consignorGstin, setConsignorGstin] = useState('');
  const [consignorPhoneNo, setConsignorPhoneNo] = useState('');
  const [consigneeName, setConsigneeName] = useState('');
  const [consigneeAddress, setConsigneeAddress] = useState('');
  const [consigneeCity, setConsigneeCity] = useState('');
  const [consigneePincode, setConsigneePincode] = useState('');
  const [consigneeGstin, setConsigneeGstin] = useState('');
  const [consigneePhoneNo, setConsigneePhoneNo] = useState('');
  const [gcNo, setGcNo] = useState('');
  const [gcDate, setGcDate] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [goodsInvoiceNo, setGoodsInvoiceNo] = useState('');
  const [goodsInvoiceDate, setGoodsInvoiceDate] = useState('');
  const [noOfPackages1, setNoOfPackages1] = useState('');
  const [contents1, setContents1] = useState('');
  const [weight1, setWeight1] = useState('');
  const [noOfPackages2, setNoOfPackages2] = useState('');
  const [contents2, setContents2] = useState('');
  const [weight2, setWeight2] = useState('');
  const [vehicleNo, setVehicleNo] = useState('');
  const [invoiceValue, setInvoiceValue] = useState('');
  const [eWayBillNo, setEWayBillNo] = useState('');
  const [eWayBillExpiryDate, setEWayBillExpiryDate] = useState('');


  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }

  const generateDocument = () => {

    if (!consignorName || !consignorAddress || !consignorCity || !consignorPincode ||
      !consignorGstin || !consigneeName || !consigneeAddress ||
      !consigneeCity || !consigneePincode || !consigneeGstin ||
      !gcNo || !gcDate || !from || !to || !goodsInvoiceNo || !goodsInvoiceDate ||
      !noOfPackages1 || !contents1 || !weight1  || !vehicleNo || !invoiceValue || !eWayBillNo || !eWayBillExpiryDate) {
      toast.error('Please enter required fields marked with red labels.');
      return;
  }

  if(consignorGstin.length!==15 || consigneeGstin.length!==15){
    toast.error('Please enter valid GSTIN number. GSTIN number should be of 15 digits.');
    return;
  }
  


    loadFile(invoicetemp, function (error, content) {
      if (error) {
        throw error;
      }
      var zip = new PizZip(content);
      var doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      doc.setData({
        consignorName: consignorName,
        consignorAddress: consignorAddress,
        consignorCity: consignorCity,
        consignorPincode: consignorPincode,
        consignorGstin: consignorGstin,
        consignorPhoneNo: consignorPhoneNo,
        consigneeName: consigneeName,
        consigneeAddress: consigneeAddress,
        consigneeCity: consigneeCity,
        consigneePincode: consigneePincode,
        consigneeGstin: consigneeGstin,
        consigneePhoneNo: consigneePhoneNo,
        gcNo: gcNo,
        gcDate: gcDate,
        from: from,
        to: to,
        goodsInvoiceNo: goodsInvoiceNo,
        goodsInvoiceDate: goodsInvoiceDate,
        noOfPackages1: noOfPackages1,
        contents1: contents1,
        weight1: weight1,
        noOfPackages2: noOfPackages2,
        contents2: contents2,
        weight2: weight2,
        vehicleNo: vehicleNo,
        invoiceValue: invoiceValue,
        eWayBillNo: eWayBillNo,
        eWayBillExpiryDate: eWayBillExpiryDate
      });
      
      try {
        doc.render();
      } catch (error) {
        throw error;
      }
      const docxFilePath = `gc_${gcNo}.docx`;
      var out = doc.getZip().generate({
        type: 'blob',
        mimeType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      saveAs(out, docxFilePath);
    });

    try {
      const {data} =axios.post(addconsignmentroute, {
        consignorName,
        consignorAddress,
        consignorCity,
        consignorPincode,
        consignorGstin,
        consignorPhoneNo,
        consigneeName,
        consigneeAddress,
        consigneeCity,
        consigneePincode,
        consigneeGstin,
        consigneePhoneNo,
        gcNo,
        gcDate,
        from,
        to,
        goodsInvoiceNo,
        goodsInvoiceDate,
        noOfPackages1,
        contents1,
        weight1,
        noOfPackages2,
        contents2,
        weight2,
        vehicleNo,
        invoiceValue,
        eWayBillNo,
        eWayBillExpiryDate
      });

      toast.success("Bilty created successfully and posted to database. You can view and download any bilty generated here in admin website", {
        duration: 8000,
      });   

    } catch (error) {
      console.error(error); 
    }

  };
    


  return (
    <div className="invoice">
      <Toaster />
      <h4>generate bilty/GR</h4>
      <p>note: all fields with red labels are mandatory. 
      Enter Details Accurately to Prevent Heavy Government Fines
      </p>
      <form className='invoiceform'>
        <h5>Consignor Details</h5>
        <div className="input-bx">
  <input
    type="text"
    value={consignorName}
    onChange={(e) => setConsignorName(e.target.value)}
    placeholder="Consignor Name"
  />
  <span className="imp">consignor name</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={consignorAddress}
    onChange={(e) => setConsignorAddress(e.target.value)}
    placeholder="Consignor Address"
  />
  <span className="imp">consignor address</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={consignorCity}
    onChange={(e) => setConsignorCity(e.target.value)}
    placeholder="Consignor City"
  />
  <span className="imp">consignor city</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={consignorPincode}
    onChange={(e) => setConsignorPincode(e.target.value)}
    placeholder="Consignor Pincode"
  />
  <span className="imp">consignor pincode</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={consignorGstin}
    onChange={(e) => setConsignorGstin(e.target.value)}
    placeholder="Consignor GSTIN"
  />
  <span className="imp">consignor GSTIN</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={consignorPhoneNo}
    onChange={(e) => setConsignorPhoneNo(e.target.value)}
    placeholder="Consignor Phone No."
  />
  <span >consignor phone no.</span>
</div>
<h5>Consignee Details</h5>
<div className="input-bx">
  <input
    type="text"
    value={consigneeName}
    onChange={(e) => setConsigneeName(e.target.value)}
    placeholder="Consignee Name"
  />
  <span className="imp">consignee name</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={consigneeAddress}
    onChange={(e) => setConsigneeAddress(e.target.value)}
    placeholder="Consignee Address"
  />
  <span className="imp">consignee address</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={consigneeCity}
    onChange={(e) => setConsigneeCity(e.target.value)}
    placeholder="Consignee City"
  />
  <span className="imp">consignee city</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={consigneePincode}
    onChange={(e) => setConsigneePincode(e.target.value)}
    placeholder="Consignee Pincode"
  />
  <span className="imp">consignee pincode</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={consigneeGstin}
    onChange={(e) => setConsigneeGstin(e.target.value)}
    placeholder="Consignee GSTIN"
  />
  <span className="imp">consignee GSTIN</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={consigneePhoneNo}
    onChange={(e) => setConsigneePhoneNo(e.target.value)}
    placeholder="Consignee Phone No."
  />
  <span >consignee phone no.</span>
</div>
<h5>GC Details</h5>
<div className="input-bx">
  <input
    type="text"
    value={gcNo}
    onChange={(e) => setGcNo(e.target.value)}
    placeholder="GC No."
  />
  <span className="imp">GC no.</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={gcDate}
    onChange={(e) => setGcDate(e.target.value)}
    placeholder="GC Date"
  />
  <span className="imp">GC date</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={from}
    onChange={(e) => setFrom(e.target.value)}
    placeholder="From"
  />
  <span className="imp">from</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={to}
    onChange={(e) => setTo(e.target.value)}
    placeholder="To"
  />
  <span className="imp">to</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={goodsInvoiceNo}
    onChange={(e) => setGoodsInvoiceNo(e.target.value)}
    placeholder="Goods Invoice No."
  />
  <span className="imp">goods invoice no.</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={goodsInvoiceDate}
    onChange={(e) => setGoodsInvoiceDate(e.target.value)}
    placeholder="Goods Invoice Date"
  />
  <span className="imp">goods invoice date</span>
</div>
<div className="input-bx">
  <input
    type="text"
    value={invoiceValue}
    onChange={(e) => setInvoiceValue(e.target.value)}
    placeholder="Invoice Value"
  />
  <span className="imp">invoice value</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={vehicleNo}
    onChange={(e) => setVehicleNo(e.target.value)}
    placeholder="vehicle no."
  />
  <span className="imp">Vehicle No.</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={eWayBillNo}
    onChange={(e) => setEWayBillNo(e.target.value)}
    placeholder="E-Way Bill No."
  />
  <span className="imp">E-Way Bill no.</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={eWayBillExpiryDate}
    onChange={(e) => setEWayBillExpiryDate(e.target.value)}
    placeholder="E-Way Bill Expiry Date"
  />
  <span className="imp">E-Way Bill expiry date</span>
</div>
<h5>Goods Details</h5>
<div className="input-bx">
  <input
    type="text"
    value={noOfPackages1}
    onChange={(e) => setNoOfPackages1(e.target.value)}
    placeholder="No. of Packages 1"
  />
  <span className="imp">no. of packages 1</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={contents1}
    onChange={(e) => setContents1(e.target.value)}
    placeholder="Contents 1"
  />
  <span className="imp">contents 1</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={weight1}
    onChange={(e) => setWeight1(e.target.value)}
    placeholder="Weight 1"
  />
  <span className="imp">weight 1</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={noOfPackages2}
    onChange={(e) => setNoOfPackages2(e.target.value)}
    placeholder="No. of Packages 2"
  />
  <span >no. of packages 2</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={contents2}
    onChange={(e) => setContents2(e.target.value)}
    placeholder="Contents 2"
  />
  <span >contents 2</span>
</div>

<div className="input-bx">
  <input
    type="text"
    value={weight2}
    onChange={(e) => setWeight2(e.target.value)}
    placeholder="Weight 2"
  />
  <span >weight 2</span>
</div>

      </form>
      <div onClick={generateDocument}>
        <div className="invoice-button">
      <Button >Generate bilty/GR</Button>
      </div>
      </div>
    </div>
  );
};

export default Bilty;