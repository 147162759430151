import React from 'react'
import './loadingslip.css'

function Loadingslip() {
  return (
    <div className='loadingslip-container'>
        <h4 >Coming Soon</h4>
        <p > Still developing the  loading slip generator.</p>
    </div>
  )
}

export default Loadingslip;