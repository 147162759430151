

export const menuData = [
  {
    id: 1,
    title: "invoice",
    navname: "invoice", 
  },
  {
    id: 2,
    title: "bilty/GR",
    navname: "bilty",
  },
  {
    id: 3,
    title: "loadingslip",
    navname: "loadingslip",
  },
];

export const btnData = {
  title: "adminPanel",
  link: "https://admin.gatividhits.com/",
};


