import React, { useState } from 'react'
import './navmenu.css'

function Navmenu({ handleOptionChange }) {

    const [selectedOption, setSelectedOption] = useState('invoice');

    
    const handleOptionClick = (option) => {
        handleOptionChange(option);
        setSelectedOption(option);
      };

  return (
    <div className='navmenucontainer'>
    <h2 className="feature-header"> Document Automation system</h2>
          <p className="feature-sub-header">Centralized dynamic document automation/generation system for gatividhi TransSolutions private limited</p>
      <nav>
  <ul>
    <li
      className={selectedOption === 'invoice' ? 'selected' : ''}
      onClick={() => handleOptionClick('invoice')}
    >
      <a  >Generate invoice</a>
    </li>
    <li
    onClick={() => handleOptionClick('bilty')}
      className={selectedOption === 'bilty' ? 'selected' : ''}
    >
      <a  >Generate bilty/GR </a>
    </li>
    <li
    onClick={() => handleOptionClick('loadingslip')}
      className={selectedOption === 'loadingslip' ? 'selected' : ''}
    >
      <a  >Generate loadingSlip </a>
    </li>
   
  </ul>
</nav>
    </div>
  )
}

export default Navmenu